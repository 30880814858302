<template>
  <v-dialog
    v-model="displayed"
    :max-width="'600px'"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title class="justify-center text-uppercase headline">
        <div class="d-flex justify-center flex-grow-1">
          <span>
            {{ titleText }}
          </span>
        </div>
      </v-card-title>
      <v-divider />
      <div class="d-flex">
        <v-form ref="create-gantt-form" class="flex-1">
          <div class="px-10 pb-5">
            <v-row class="d-flex">
              <v-col class="pa-0" cols="4">
                <ant-input
                  :label="$t('general.priority') | capitalize"
                  class="mr-2 flex-1"
                >
                  <template #input-field>
                    <v-select
                      v-model="task.priority"
                      :disabled="!canUpdate"
                      :items="['low', 'normal', 'high', 'urgent']"
                      :placeholder="$t('general.priority') | capitalize"
                      :rules="[rules.required]"
                      dense
                      filled
                      hide-details
                    >
                      <template #selection="{ item, index }">
                        <v-icon :color="getPriorityColor(item)" class="mr-2"
                          >{{ getPriorityIcon(item) }}
                        </v-icon>
                        {{ item }}
                      </template>
                      <template #item="{ item, attrs, on }">
                        <v-list-item v-bind="attrs" v-on="on">
                          <v-list-item-icon>
                            <v-icon :color="getPriorityColor(item)"
                              >{{ getPriorityIcon(item) }}
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-select>
                  </template>
                </ant-input>
              </v-col>
              <v-col class="pa-0" cols="8">
                <ant-input
                  :label="$t('general.title') | capitalize"
                  class="flex-1"
                >
                  <template #input-field>
                    <div class="d-flex">
                      <v-text-field
                        v-model="task.title"
                        :disabled="!canUpdate"
                        :placeholder="$t('general.title')"
                        :rules="[rules.required]"
                        dense
                        autofocus
                        filled
                        hide-details
                      />
                    </div>
                  </template>
                </ant-input>
              </v-col>
            </v-row>

            <v-row class="d-flex">
              <v-col class="pa-0" cols="6">
                <ant-input label="Start current" is-optional class="mr-2 flex-1">
                  <template #input-field>
                    <v-menu
                      v-model="menus.start_date"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="task.planned_start"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          hide-details
                          dense
                          clearable
                          :placeholder="today"
                          filled
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="task.planned_start"
                        no-title
                        scrollable
                        @input="menus.start_date = false"
                      />
                    </v-menu>
                  </template>
                </ant-input>
              </v-col>
              <v-col class="pa-0" cols="6">
                <ant-input label="End current" is-optional>
                  <template #input-field>
                    <v-menu
                      v-model="menus.end_date"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="task.planned_end"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          hide-details
                          dense
                          clearable
                          :placeholder="today"
                          filled
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="task.planned_end"
                        no-title
                        scrollable
                        @input="menus.end_date = false"
                      />
                    </v-menu>
                  </template>
                </ant-input>
              </v-col>
            </v-row>
            <v-row cols="12">
              <task-label
                :editing="canUpdate"
                :labels="task.labels"
                :project-id="task?.project?.id"
                title="Party"
                class="mt-5"
                @on-change="onLabelChange"
              />
            </v-row>
            <ant-input :label="`${$t('system.tasks.executedBy')}`">
              <template #input-field>
                <transition mode="out-in" name="simple-fade">
                  <v-edit-dialog v-if="!task.assigned_user" persistent>
                    <v-icon class="ant-icon"> mdi-plus</v-icon>
                    <template #input>
                      <v-combobox
                        v-model="task.assigned_user"
                        :disabled="!task.project"
                        :items="users"
                        item-text="email"
                        item-value="id"
                        placeholder="email"
                        return-object
                        single-line
                      />
                    </template>
                  </v-edit-dialog>
                  <div v-else class="d-flex align-center">
                    <v-avatar
                      :color="
                        !canUpdate ? 'var(--v-secondary-lighten3)' : 'primary'
                      "
                      class="mr-2 white--text"
                      size="30"
                    >
                      {{
                        `${task.assigned_user.firstname.charAt(
                          0
                        )}${task.assigned_user.lastname.charAt(0)}`
                      }}
                    </v-avatar>
                    <div
                      :style="{
                        fontSize: '12px',
                        color: !canUpdate
                          ? 'var(--v-secondary-lighten3)'
                          : 'inherit',
                      }"
                      class="d-flex align-center"
                    >
                      {{ task.assigned_user.email }}
                      <v-icon
                        v-if="canUpdate"
                        :class="[canUpdate ? 'ant-icon' : 'icon-disabled']"
                        small
                        @click="unsetSubscribedUser"
                        >mdi-close
                      </v-icon>
                    </div>
                  </div>
                </transition>
              </template>
            </ant-input>
            <slot name="add-task-options" />
            <ant-input
              :label="`${$t('general.description')}` | capitalize"
              is-optional
            >
              <template #input-field>
                <v-textarea
                  v-model="task.description"
                  :disabled="!canUpdate"
                  :placeholder="$t('system.tasks.addDescription')"
                  :rules="[rules.maxLength]"
                  filled
                  hide-details
                />
              </template>
            </ant-input>
            <v-spacer />
          </div>
        </v-form>
      </div>

      <v-card-actions class="ant-border-top ant-dialog-actions-bg">
        <v-spacer />
        <v-col class="pa-0" cols="6">
          <v-btn
            v-if="isEditing"
            :disabled="!canUpdate"
            :loading="
              $wait.is('module.tasks-gantt.tasks.create') ||
              $wait.is('module.tasks-gantt.tasks.update')
            "
            color="error"
            elevation="0"
            small
            @click.stop="onTaskDelete"
          >
            {{ $t('general.delete') }}
          </v-btn>
        </v-col>
        <v-col class="pa-0 justify-end d-flex" cols="6">
          <v-btn
            :disabled="
              $wait.is('module.tasks-gantt.tasks.create') ||
              $wait.is('module.tasks-gantt.tasks.update')
            "
            color="error"
            small
            text
            @click.stop="closeDialog"
          >
            {{ $t('general.cancel') }}
          </v-btn>
          <v-btn
            :disabled="!canUpdate"
            :loading="
              $wait.is('module.tasks-gantt.tasks.create') ||
              $wait.is('module.tasks-gantt.tasks.update')
            "
            color="primary"
            elevation="0"
            small
            @click.stop="onTaskSubmit"
          >
            {{ isEditing ? $t('general.save') : $t('general.create') }}
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AntInput from '@/components/AntInput.vue';
import moment from 'moment/moment';
import { mapGetters } from 'vuex';
import TaskHelper from '@/services/task-helper';
import TaskLabel from '@/components/Tasks/TaskLabel.vue';
import { getProjectMembersWithTaskPermissions } from '@/services/api/projects.api';

export default {
  name: 'DhmeProjectGanttDialog',
  components: { TaskLabel, AntInput },
  props: {
    project: {
      type: Object,
      required: true,
    },
    parentId: {
      type: String,
      default: '',
    },
    selectedTask: {
      type: [Object, null],
      required: false,
      default: null,
    },
    isShown: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      isPrivate: false,
      displayed: false,
      today: moment().format('YYYY-MM-DD'),
      task: {
        priority: '',
        assigned_user: undefined,
        title: null,
        labels: [],
        options: {
          type: 'rfi',
        },
      },
      users: [],
      menus: {
        start_date: false,
        end_date: false,
        due_date: false,
      },
      rules: {
        required: (value) => !!value || 'Required.',
        maxLength: (value) =>
          value ? value.length < 250 || 'Max 250 characters' : true,
      },
      sbsLoading: false,
    };
  },
  computed: {
    ...mapGetters(['authenticatedUser', 'selectedLicense']),
    isEditing() {
      return !!this.selectedTask;
    },
    canUpdate() {
      return (
        !this.isEditing ||
        (this.authenticatedUser?.id === this.selectedTask?.assigned_to?.id &&
          this.selectedTask?.status === 'open')
      );
    },
    titleText() {
      const mode = this.isEditing
        ? this.canUpdate
          ? this.$t('general.edit')
          : null
        : this.$t('general.create');
      const number = this.selectedTask?.number
        ? `(${this.selectedTask?.number})`
        : null;
      return [mode, 'task', number].filter(Boolean).join(' ');
    },
  },
  watch: {
    async isShown(value) {
      this.displayed = value;
      if (!value) {
        this.resetData();
        return;
      }
      this.task.project = this.project;
      this.task.priority = 'normal';
      if (this.parentId) this.task.parent = this.parentId;

      if (this.isEditing) {
        this.task = {
          id: this.selectedTask.id,
          title: this.selectedTask.title,
          project: this.selectedTask.task_project,
          priority: this.selectedTask.priority,
          description: this.selectedTask.description,
          assigned_user: this.selectedTask.assigned_to,
          due: this.selectedTask?.due
            ? moment(this.selectedTask.due).format('YYYY-MM-DD')
            : null,
          planned_start: this.selectedTask?.planned_start
            ? moment(this.selectedTask.planned_start).format('YYYY-MM-DD')
            : null,
          planned_end: this.selectedTask?.planned_end
            ? moment(this.selectedTask.planned_end).format('YYYY-MM-DD')
            : null,
          license: this.selectedTask.license,
          status: this.selectedTask.status,
          labels: this.selectedTask.labels,
        };
      }
      this.users = await getProjectMembersWithTaskPermissions(
        this.task.project.id
      );
    },
    users(value) {
      if (value && !this.isEditing) {
        const user = value.find(
          (user) => user.id === this.authenticatedUser.id
        );
        if (user) this.task.assigned_user = user;
      }
    },
  },
  methods: {
    unsetSubscribedUser() {
      if (this.canUpdate) {
        this.task.assigned_user = null;
      }
    },
    resetData() {
      this.$refs['create-gantt-form'].reset();
      this.task = {
        priority: '',
        assigned_user: undefined,
        title: null,
        labels: [],
        options: {
          type: 'rfi',
        },
        sbsObject: null,
      };
    },
    closeDialog() {
      this.$emit('closeDialog');
      this.resetData();
    },
    async onTaskSubmit() {
      if (this.$refs['create-gantt-form'].validate()) {
        if (!this.task.title) {
          this.$store.commit('showNotification', {
            content: this.$t('system.tasks.notifications.fillTitle'),
            color: 'error',
          });
          return;
        }
        if (!this.task.assigned_user) {
          this.$store.commit('showNotification', {
            content: this.$t('system.tasks.notifications.addUser'),
            color: 'error',
          });
          return;
        }

        const startOfTheDay = moment().startOf('day');

        let body = {
          id: this.task.id,
          parent: this.parentId,
          project: this.task.project.id,
          title: this.task.title,
          priority: this.task.priority,
          description: this.task.description,
          assigned_to: this.task.assigned_user.id || this.task.assigned_to.id,
          due:
            this.task?.due &&
            moment(this.task.due).format('YYYY-MM-DD HH:mm:ss'),
          planned_start: this.task.planned_start
            ? moment(this.task.planned_start).format('YYYY-MM-DD HH:mm:ss')
            : moment(startOfTheDay).format('YYYY-MM-DD HH:mm:ss'),
          planned_end: this.task.planned_end
            ? moment(this.task.planned_end).format('YYYY-MM-DD HH:mm:ss')
            : moment(startOfTheDay).add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
          license: this.selectedLicense.id,
          labels: this.task?.labels?.map((l) => l.id),
          options: this.task.options,
        };

        this.$emit('onSubmit', { isEditing: this.isEditing, payload: body });
      }
    },
    onTaskDelete() {
      this.$emit('onDelete', this.task.id);
    },
    getPriorityIcon(priority) {
      return TaskHelper.getPriorityIcon(priority);
    },
    getPriorityColor(priority) {
      return TaskHelper.getPriorityColor(priority);
    },
    onLabelChange(labels) {
      this.task.labels = labels;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-disabled {
  &:hover {
    cursor: default;
  }
}

.row {
  margin: 0 !important;
}
</style>
