export const baseTasksTemplate = {
  'dhme-init-phase': [
    {
      friendly_id: '1',
      title: 'Start Project',
      options: {
        type: 'dhme-base-phase',
      },
      duration: 0.1,
    },
    {
      friendly_id: '2',
      title: 'Setup planning',
      options: {
        type: 'dhme-base-phase',
      },
      duration: 0.9,
    },
  ],
};
