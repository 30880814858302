<script>
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import { queryTasksV2 } from '@/services/api/v2/tasks.v2.api';
import { mapGetters } from 'vuex';
import { startPhaseAndGenerateTasks } from '@/modules/daiwa-house-modular-europe/PhaseTransfer/startPhase';
import { mapTasks } from '@/services/dhtmlx-gantt/chart-helper';
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';
import DhmeProjectGanttChart from '@/modules/daiwa-house-modular-europe/ProjectGantt/DhmeProjectGanttChart.vue';
import TasksGanttChart from '@/components/Charts/TasksGanttChart.vue';
import TasksGanttDefaultItem from '@/components/Charts/TasksGanttDefaultItem.vue';
import moment from 'moment';
import { executeCustomModuleCall } from '@/services/api/module.api';
import { DHME_PHASE_TRANSFER, DHME_QR2FORM } from '@/modules/modules';

export default {
  name: 'DhmePhaseTransfer',
  components: {
    TasksGanttDefaultItem,
    TasksGanttChart,
    ModuleNavigationBar,
  },
  data: () => {
    return {
      phases: [],
      phasesLoading: false,
      focusedPhase: null,
      focusedPhaseNav: 'planning',

      focusedPhaseLoading: false,
      planningTasks: [],
      wkbTasks: [],
      risks: [],
    };
  },
  computed: {
    ...mapGetters(['project', 'isDaiwaLicense']),

    moduleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_PHASE_TRANSFER
      )?.id;
    },
  },
  watch: {
    focusedPhase(value, oldValue) {
      if (value && value?.id !== this.$route.params.phase) {
        this.$router.push({ params: { phase: value.id } });
        this.focusedPhaseNav = 'planning';
        this.fetchPlanning();
      }
    },
    focusedPhaseNav(value) {
      switch (value) {
        case 'planning':
          this.fetchPlanning();
          break;
        case 'tasks':
          this.fetchWkbTasks();
          break;
        case 'risks':
          this.fetchRisks();
          break;
      }
    },
  },
  async mounted() {
    await this.fetchPhases();
    if (this.$route.params.phase) {
      this.focusedPhase = this.phases.find(
        (p) => p.id === this.$route.params.phase
      );
      await this.fetchPlanning();
    }
  },
  methods: {
    async fetchPhases() {
      this.phasesLoading = true;
      try {
        let { tasks } = await queryTasksV2(this.project.license, [
          {
            column: 'project',
            operator: '=',
            values: [this.project.id],
          },
          {
            column: 'type',
            operator: '=',
            values: [
              'dhme-init-phase',
              'dhme-structure-design-phase',
              'dhme-temporary-design-phase',
              'dhme-definitive-design-phase',
              'dhme-environmental-permit-phase',
              'dhme-production-phase',
              'dhme-assembly-phase',
              'dhme-construction-site-phase',
              'dhme-contact-formation-phase',
              'dhme-production-drawing-phase',
              'dhme-deliver-time-phase',
              'dhme-install-assemble-finish-phase',
            ],
          },
        ]);
        this.phases = tasks;
      } catch (e) {}
      this.phasesLoading = false;
    },
    async fetchPlanning() {
      this.focusedPhaseLoading = true;
      try {
        let { tasks } = await queryTasksV2(this.project.license, [
          {
            column: 'project',
            operator: '=',
            values: [this.project.id],
          },
          {
            column: 'type',
            operator: '=',
            values: ['dhme-base-phase'],
          },
          {
            column: 'parent',
            operator: '=',
            values: [this.focusedPhase.id],
          },
        ]);
        this.planningTasks = tasks;
      } catch (e) {}
      this.focusedPhaseLoading = false;
    },
    async fetchWkbTasks() {
      this.focusedPhaseLoading = true;
      try {
        let { tasks } = await queryTasksV2(this.project.license, [
          {
            column: 'project',
            operator: '=',
            values: [this.project.id],
          },
          {
            column: 'type',
            operator: '=',
            values: ['dhme-quality-assurance'],
          },
          {
            column: 'parent',
            operator: '=',
            values: [this.focusedPhase.id],
          },
        ]);
        this.wkbTasks = tasks;
      } catch (e) {}
      this.focusedPhaseLoading = false;
    },
    async fetchRisks() {
      this.focusedPhaseLoading = true;
      try {
        let { risks } = await queryTablesV2({
          tables: [
            {
              name: 'CFFA_DHME_PROJECT_INTAKE_RISKS',
              project: this.project.master_id,
              as: 'risks',
              columns: [
                {
                  name: 'project_id',
                  conditions: [
                    {
                      operator: '=',
                      values: [this.project.id],
                    },
                  ],
                },
                {
                  name: 'title',
                },
                {
                  name: 'description',
                },
                {
                  name: 'chance',
                },
                {
                  name: 'consequence',
                },
              ],
            },
          ],
        });
        this.risks = risks.records;
      } catch (e) {}
      this.focusedPhaseLoading = false;
    },

    async startPhase() {
      this.startPhaseLoading = true;
      await startPhaseAndGenerateTasks(this.project, this.focusedPhase);

      this.focusedPhase.task_type.custom_10 = 'initialised';

      if (this.focusedPhase.task_type.type === 'dhme-assembly-phase') {
        await executeCustomModuleCall(
          this.project.id,
          this.moduleId,
          'generateLeanformsForms'
        );
      }
      this.startPhaseLoading = false;
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },
  },
};
</script>

<template>
  <div class="d-flex flex-column overflow-y-auto">
    <module-navigation-bar title="Phase Transfer">
      <template #module-nav-logo>
        <img
          v-if="isDaiwaLicense"
          alt=""
          height="40px"
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
        />
      </template>
    </module-navigation-bar>
    <div class="flex-grow-1 d-flex flex-scroll-height">
      <div
        style="width: 300px"
        class="d-flex flex-column overflow-y-auto pr-5 background-white"
      >
        <div class="flex-grow-1 overflow-y-auto pb-5">
          <v-list two-line subheader>
            <v-subheader
              >Phases
              <v-spacer />
              <v-progress-circular
                v-show="phasesLoading"
                color="primary"
                width="1"
                indeterminate
                size="20"
              />
            </v-subheader>
            <v-list-item-group v-model="focusedPhaseNav" color="primary">
              <v-list-item
                v-for="phase in phases"
                :key="phase.id"
                @click="focusedPhase = phase"
              >
                <v-list-item-icon>
                  <v-icon v-if="phase.status === 'open'"
                    >mdi-checkbox-blank-circle-outline</v-icon
                  >
                  <v-icon v-if="phase.status === 'completed'" color="success"
                    >mdi-check-circle-outline</v-icon
                  >
                  <v-icon v-if="phase.status === 'canceled'" color="warning"
                    >mdi-cancel</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ phase.title }}</v-list-item-title>
                  <v-list-item-subtitle style="font-size: 10px"
                    >{{ formatDate(phase.planned_start) }}/{{
                      formatDate(phase.planned_end)
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip
                    v-if="phase.task_type.custom_10 === 'initialised'"
                    color="primary"
                    small
                  >
                    started
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </div>
      <div class="d-flex flex-column flex-grow-1">
        <div
          v-if="focusedPhase"
          class="background-white d-flex align-center pr-5"
          style="height: 40px"
        >
          <v-btn-toggle v-model="focusedPhaseNav" tile color="primary" group>
            <v-btn height="32" value="planning">Planning</v-btn>
            <v-btn height="32" value="tasks">Tasks</v-btn>
            <v-btn height="32" value="risks">Risks</v-btn>
          </v-btn-toggle>
          <v-spacer />
          <v-btn
            v-if="focusedPhase"
            height="32"
            elevation="0"
            :disabled="focusedPhase.task_type.custom_10 === 'initialised'"
            color="primary"
            tile
            @click="startPhase"
            >Start</v-btn
          >
        </div>
        <v-progress-linear
          v-show="focusedPhaseLoading"
          indeterminate
          color="primary"
          height="5"
        />
        <div
          class="d-flex overflow-x-auto pa-5 full-height"
          style="width: calc(100vw - 300px)"
        >
          <tasks-gantt-chart
            v-if="focusedPhaseNav === 'planning'"
            ref="tasks-gantt"
            :tasks="planningTasks"
          >
            <template #ant-gantt-actions>
              <v-icon
                class="mr-2"
                small
                @click="$refs['add-task-dialog'].displayed = true"
                >mdi-plus</v-icon
              >
            </template>
            <template #task-header-items>
              <tasks-gantt-default-item
                v-for="(task, index) in planningTasks"
                :key="`ant-gantt-header-${task.id}`"
                :task="task"
                :index="index"
                :parents="[]"
                :show-labels="
                  $refs['tasks-gantt'] ? $refs['tasks-gantt'].showLabels : false
                "
                :can-load-children="false"
              />
            </template>
          </tasks-gantt-chart>
          <v-data-table
            v-if="focusedPhaseNav === 'tasks'"
            class="flex-grow-1"
            :headers="[
              { text: 'Status', value: 'status' },
              { text: 'Type', value: 'type' },
              { text: 'Title', value: 'title' },
              { text: 'Description', value: 'description' },
              { text: 'Start', value: 'start_date' },
              { text: 'End', value: 'end_date' },
              { text: 'Due', value: 'due' },
              { text: 'User', value: 'assigned_to' },
            ]"
            :items="wkbTasks"
          >
            <template #item.status="{ value }">
              <v-icon v-if="value === 'open'"
                >mdi-checkbox-blank-circle-outline</v-icon
              >
              <v-icon v-if="value === 'completed'" color="success"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-if="value === 'canceled'" color="warning"
                >mdi-cancel</v-icon
              >
            </template>

            <template #item.type="{ item }">
              <v-tooltip v-if="item.task_type.custom_2 === 'leanform'" left>
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-form-select</v-icon>
                </template>
                <span>Leanform</span>
              </v-tooltip>
              <v-tooltip v-if="item.task_type.custom_2 === 'document'" left>
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-file</v-icon>
                </template>
                <span>Document</span>
              </v-tooltip>
              <v-tooltip v-if="item.task_type.custom_2 === 'task'" left>
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-clipboard-check</v-icon>
                </template>
                <span>Task</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-data-table
            v-if="focusedPhaseNav === 'risks'"
            class="flex-grow-1"
            :headers="[
              { text: 'Title', value: 'title' },
              { text: 'Description', value: 'description' },
              { text: 'Chance', value: 'chance' },
              { text: 'Consequence', value: 'consequence' },
              { text: 'Risk', value: 'risk' },
            ]"
            :items="risks"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '~dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css';
::v-deep {
  .gantt_task_progress {
    text-align: left;
    box-sizing: border-box;
    color: white;
    font-weight: bold;
  }

  .gantt_task_cell.day-off {
    background-color: #eff5fd;
  }
  .gantt_task_row.gantt_selected .gantt_task_cell.day-off {
    background-color: #eff5fd;
  }
  .gantt_scale_cell {
    user-select: none;
    &.current_date {
      background-color: lightgray;
    }
  }
  .gantt_task_cell {
    &.current_date {
      background-color: lightgray;
    }
  }
  .gantt_tree_indent {
    width: 10px;
  }
  .status {
    background-color: #1de9b6;
  }
}
</style>
